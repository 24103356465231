import React, { Suspense, useMemo } from 'react'
import { PRODUCT_CREATE_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { createProduct, getAllProductDatatable } from './ProductAction'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'
import { ProductFormInput } from '../../../../../helpers/UIProductConstanta'
import { log } from 'console'

const ProductCreateForm = React.lazy(() => import('./ProductCreateComponent/ProductCreateForm'))

const ProductCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const saveAction = (data: ProductFormInput) => {
    dispatch<any>(createProduct(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllProductDatatable(queryParams));
      // Back
      navigate("/master/product")
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{PRODUCT_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/master/product"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <ProductCreateForm onSubmit={saveAction} />
      </Suspense>
    </>
  )
}

export default ProductCreate 
