/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { PageTitle } from '../../../_layout/core'
import { Alert, Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import useExamProgress from '../../../_hooks/useExamProgress'
import { KTIcon, toAbsoluteUrl } from '../../../_layout/helpers'
import { epochToTimeFormatStringDashboard } from '../../../helpers/FuncHelpers'
import { Controller, useForm } from 'react-hook-form'
import SVG from 'react-inlinesvg'
import { ModalConfirm } from '../../../_component/Modal/ModalConfirm'
import { useAuth } from '../../../_context/AuthContext'
import { checkAuthLinkMenu } from '../../../helpers/AuthHelpers'

type props = {
  id: string,
  showModal: boolean,
  onHide: () => void
  // submitAction: () => void
}
const SubmitModal: FC<props> = ({ showModal, onHide, id }) => {
  const { submitData } = useExamProgress()
  function submitAction() {
    submitData(id).then(() => {
      onHide()
    }).catch((e) => {
      console.log("error");
    })
  }
  return (
    <ModalConfirm title={"Submit Exam"} confirmText={"Are you sure to submit this data ?"} loadingText={"Submitting"} show={showModal} onHide={onHide} submitAction={submitAction} isLoading={false} />
  )
}

const DashboardPage: FC = () => {
  const { listExamProgress, getNext, getPrev, getSearch, count } = useExamProgress()
  const [propsForSubmit, setPropsForsubmit] = useState({
    id: '',
    show: false,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  })

  function onSubmit(req: any) {
    getSearch(req.search)
  }

  return (
    <>
      <SubmitModal showModal={propsForSubmit.show} onHide={() => {
        setPropsForsubmit({ id: '', show: false })
      }} id={propsForSubmit.id} />
      <div className='d-flex justify-content-between align-items-center mb-4'>
      </div>
      <div className={`card card-xl-stretch mt-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-dark'>Users Exam</h3>
          <div className='card-toolbar'>
            <Form className='d-flex' onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="search"
                defaultValue=''
                control={control}
                render={({ field }) => (
                  <div className='d-flex align-items-center position-relative me-4'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      {...field}
                      type='text'
                      className='form-control form-control-white form-control-sm w-150px ps-9'
                      placeholder='Search'
                    />
                  </div>
                )} />
              <button className='btn btn-primary btn-sm' type='submit'>
                Search Email
              </button>
            </Form>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-2'>
          {/* begin::Item */}
          {listExamProgress?.entities?.map((item: any, index: number) => {
            return (
              <div key={index} className='d-flex align-items-center mb-7'>
                <div className='symbol symbol-50px me-5'>
                  <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='' alt='' />
                </div>
                <div className='flex-grow-1'>
                  <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                    {item.name}
                  </a>
                  <span className='text-muted d-block fw-semibold'>{item.email}</span>
                </div>

                <span className='text-muted d-block fw-semibold'>{epochToTimeFormatStringDashboard(item.examProgressData.startTime)} - {epochToTimeFormatStringDashboard(item.examProgressData.endDate)}</span>
                <>
                  <OverlayTrigger
                    overlay={<Tooltip id='products-edit-tooltip'>Submit Exam ?</Tooltip>}
                  >
                    <button onClick={() => { setPropsForsubmit({ id: item.id, show: true }) }} className='btn btn-icon btn-light btn-sm mx-3'>
                      <span className='svg-icon svg-icon-md svg-icon-primary'>
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Send.svg')} />
                      </span>
                    </button>
                  </OverlayTrigger>
                  {/* {" "} */}
                  {/* <OverlayTrigger
                    overlay={<Tooltip id='products-edit-tooltip'>Delete this Subject?</Tooltip>}
                  >
                    <button
                      className='btn btn-icon btn-danger btn-sm mx-3'
                    >
                      <span className='svg-icon svg-icon-md svg-icon-primary'>
                        <SVG src={toAbsoluteUrl('/media/svg/delete.svg')} />
                      </span>
                    </button>
                  </OverlayTrigger> */}
                </>
              </div>
            )
          })}
          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
      <div className='d-flex flex-stack flex-wrap pt-10'>
        <div className='fs-6 fw-bold text-gray-700'>Total Data {count}</div>

        <ul className='pagination'>
          <li className='page-item previous'>
            <a href='#' onClick={() => { getPrev() }} className='page-link'>
              <i className='previous'></i>
            </a>
          </li>

          <li className='page-item next'>
            <a href='#' onClick={() => { getNext() }} className='page-link'>
              <i className='next'></i>
            </a>
          </li>
        </ul>
      </div>
    </>

  )
}

const DashboardWrapper: FC = () => {
  const { currentUser, authorization } = useAuth()
  return checkAuthLinkMenu("dashboard_menu", currentUser, authorization) ? (
    <>
      <PageTitle breadcrumbs={[]}>{'MENU.DASHBOARD'}</PageTitle>
      <Alert variant="warning">
        <Alert.Heading>Attention!</Alert.Heading>
        <p className='text-dark'>
          Be careful if you on environment <span className='text-gray-800 fs-6 fw-bold'>STAGING</span> don't use action submit. Table monitoring below is integrated with data Student doing exam at the moment <span className='text-gray-800 fs-6 fw-bold'>(Data Production)</span>
        </p>
      </Alert>
      {/* <Image src={"https://storage.cloud.google.com/qonstanta/testing/ball.png"} width={"10%"} thumbnail /> */}
      <DashboardPage />
    </>
  ) : <></>
}

export { DashboardWrapper }
