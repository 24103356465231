import { Controller, useForm } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { ResultTryoutFilterTableFormInput } from '../../../../../../helpers/UIResultTyoutConstanta'
import useQuestionTryout from '../../../../../../_hooks/useQuestionTryout'
import useScheduleTryout from '../../../../../../_hooks/useScheduleTryout'
import { ScheduleTryoutFilterRequest } from '../../../../../../_data/dataSource/request/ScheduleTryoutRequest'
import { ResultTryoutFilterRequest } from '../../../../../../_data/dataSource/request/ResultTryoutRequest'
import { Dropdown } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_layout/helpers'
import ResultTryoutDataSourceImpl from '../../../../../../_data/dataSource/ResultTryoutDataSourceImpl'

const dataSource = new ResultTryoutDataSourceImpl()
const TryoutResultListFilter = () => {
  const { listSeries, listTypeTryout } = useQuestionTryout();
  const { filterSchedule, listScheduleTryout } = useScheduleTryout();
  const outletContext = useOutletContext<any>().queryParams
  const { applyFilter } = outletContext

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ResultTryoutFilterTableFormInput>({
    mode: "onChange",
  })

  // watching realtime form and set to outletcontext state
  // useEffect(() => {
  //   const formValueWatch = watch((value, { name, type }) => {
  //     if (value.typeTryoutInput && value.seriesInput) {
  //       const filter: ScheduleTryoutFilterRequest = {
  //         type_tryout: value.typeTryoutInput,
  //         role: value.seriesInput,
  //       }
  //       filterSchedule(filter)
  //     }
  //     const filter: ResultTryoutFilterRequest = {
  //       schedule_id: value.scheduleInput,
  //       search: value.searchInput
  //     }
  //     applyFilter(filter)
  //   }
  //     // outletContextFilter.setFormValue(value, name, type)
  //   )
  //   return () => formValueWatch.unsubscribe()
  // }, [watch])
  // watching realtime form and set to outletcontext state END

  function changeFilterState() {
    const typeTryoutInput = getValues("typeTryoutInput")
    const seriesInput = getValues("seriesInput")
    const scheduleInput = getValues("scheduleInput")
    const searchInput = getValues("searchInput");

    if (typeTryoutInput && seriesInput) {
      const filter: ScheduleTryoutFilterRequest = {
        type_tryout: typeTryoutInput,
        exam: seriesInput,
      }
      filterSchedule(filter)
    }
    const filter: ResultTryoutFilterRequest = {
      schedule_id: scheduleInput,
      search: searchInput
    }
    applyFilter(filter)
  }

  return (
    <>
      <Form className='row'>
        {/* Type Tryout Field */}
        <div className='col-2'>
          <Controller
            name='typeTryoutInput'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Type Tryout</Form.Label>
                <Form.Select
                  {...field}
                  className='mb-3'
                  onChange={(e) => {
                    setValue("typeTryoutInput", e.target.value)
                    setValue("scheduleInput", "")
                    changeFilterState()
                  }}
                >
                  <option value=''>
                    --Please Select Type Tryout--
                  </option>
                  {listTypeTryout.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )} />
        </div>
        {/* Series Field */}
        <div className='col-2'>
          <Controller
            name="seriesInput"
            defaultValue=''
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Select Series</Form.Label>
                <Form.Select
                  {...field}
                  className='mb-3'
                  onChange={(e) => {
                    setValue("seriesInput", e.target.value)
                    setValue("scheduleInput", "")
                    changeFilterState()
                  }}
                >
                  <option value=''>
                    --Please Select Series--
                  </option>
                  {listSeries.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )} />
        </div>
        {/* Schedule Field */}
        <div className='col-2'>
          <Controller
            name="scheduleInput"
            defaultValue=''
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Select Schedule</Form.Label>
                <Form.Select
                  {...field}
                  className='mb-3'
                  onChange={(e) => {
                    setValue("scheduleInput", e.target.value)
                    changeFilterState()
                  }}
                >
                  <option value=''>
                    --Please Select Schedule--
                  </option>
                  {listScheduleTryout.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )} />
        </div>
        {/* Search Field */}
        <div className="col-2">
          <Controller
            name="searchInput"
            defaultValue=''
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Search</Form.Label>
                <Form.Control
                  {...field}
                  type='text'
                  placeholder='Search'
                  className='mb-3'
                  onChange={(e) => {
                    setValue("searchInput", e.target.value)
                    changeFilterState()
                  }}
                />
              </Form.Group>
            )} />
        </div>

        <div className="col-3 mt-9">
          <Dropdown className='d-inline-block'>
            <Dropdown.Toggle size='sm' variant="success" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {
                window.location.href = dataSource.exportExcelTmpSingle(
                  getValues("scheduleInput")!
                );
              }}>
                <span className='svg-icon svg-icon-sm svg-icon-primary'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Download.svg')} />
                </span>{" "}
                Export Result
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Form >
    </>
  )
}

export default TryoutResultListFilter
