import axios from 'axios'
import {ResultResponse, ResultTryoutScoreResponse} from './response/ResultResponse'
import ResultTryoutDataSource from './ResultTryoutDataSoruce'
import {MessageResponse} from './response/MessageResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {ApiResponse} from './response/BaseResponse'
import {alertError, alertWarning} from '../../helpers/FuncHelpers'

export default class ResultTryoutDataSourceImpl implements ResultTryoutDataSource {
  private RESULT_TO_URL: string
  private CALCULATE_URL: string

  constructor() {
    const service_schedule_url = process.env.REACT_APP_SCHEDULE_SERVICE_URL || 'api/'
    const service_calculate_url = process.env.REACT_APP_CALCULATE_SERVICE_URL || 'api/'
    this.RESULT_TO_URL = `${service_schedule_url}results`
    this.CALCULATE_URL = `${service_calculate_url}try-out-test-result`
  }
  async submitOneResult(req: any): Promise<void> {
    await axios.post(`${this.RESULT_TO_URL}`, {
      ...req,
    })
  }
  async getOneResult(scheduleId: string, userId: string): Promise<ResultResponse> {
    const response = await axios.get<ApiResponse<ResultResponse>>(
      `${this.RESULT_TO_URL}/${scheduleId}/user-simple/${userId}`
    )
    const data = response.data.data
    return data
  }
  exportExcel(id: string): string {
    if (!id) {
      alertWarning('Please select schedule')
      return '#'
    }
    return `${this.CALCULATE_URL}/export-batch-score/${id}`
  }
  exportExcelTmp(id: string): string {
    if (!id) {
      alertWarning('Please select schedule')
      return '#'
    }
    return `${this.CALCULATE_URL}/export-batch-result/${id}`
  }
  exportExcelTmpSingle(id: string): string {
    if (!id) {
      alertWarning('Please select schedule')
      return '#'
    }

    return `${this.CALCULATE_URL}/export-result/${id}`
  }
  async getDatatableBatch(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ResultResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ResultResponse>>>(
      `${this.RESULT_TO_URL}/datatable-batch`,
      req
    )

    const data = response.data.data
    return data
  }

  async calculateOne(id: string | number): Promise<string> {
    const response = await axios.get<MessageResponse>(`${this.CALCULATE_URL}/calculate/${id}`)
    return response.data.message
  }

  async calculateBatch(id: string | number): Promise<string> {
    const response = await axios.get<MessageResponse>(`${this.CALCULATE_URL}/calculate-batch/${id}`)
    return response.data.message
  }

  async getDatatableBatchScore(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ResultTryoutScoreResponse>> {
    const response = await axios.post<
      ApiResponse<BaseDatatableResponse<ResultTryoutScoreResponse>>
    >(`${this.RESULT_TO_URL}/datatable-batch-calculate`, req)

    const data = response.data.data
    return data
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<ResultResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ResultResponse>>>(
      `${this.RESULT_TO_URL}/datatable`,
      req
    )

    const data = response.data.data
    return data
  }

  getAll(params?: any): Promise<ResultResponse[]> {
    throw new Error('Method not implemented.')
  }

  getOne(id: string | number): Promise<ResultResponse> {
    throw new Error('Method not implemented.')
  }

  async deleteOne(id: string | number): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.RESULT_TO_URL}/${id}`)
    return response.data.message
  }

  create(req: any): Promise<ResultResponse> {
    throw new Error('Method not implemented.')
  }

  update(req: any, id: string | number | undefined): Promise<ResultResponse> {
    throw new Error('Method not implemented.')
  }
}
